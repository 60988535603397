import React from 'react'
import Logo from '../components/Logo'
import Navigation from '../components/Navigation'

export default function About() {
  return (
    <div>
        <Navigation />
        <Logo />
        <h1>À propos</h1>
        <br />
        <p>Cette application affiche la totalité des drapeaux du monde dynamiquement grâce à l'API RestCountries. En passant la souris sur un drapeau, vouz verrez la capitale ainsi que le nombre de la population de ce pays.</p>
        <br />
        <p>Vous pouvez régler le nombre de pays à afficher ainsi que les trier par continent.</p>
    </div>
  )
}
